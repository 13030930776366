var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('BCardActions', {
    staticClass: "mt-1",
    attrs: {
      "title": "Realtime Monitoring - Permohonan Kapal (Hulu Migas)",
      "action-collapse": "",
      "collapsed": false,
      "no-actions": true
    }
  }, [_c('h4', [_c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _c('span', [_vm._v(" Document Permohonan Kapal yang belum realisasi Nomor "), _c('strong', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("RPKRO")])], 1)])], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "rows": _vm.rows,
      "is-loading": _vm.yo_isLoading,
      "rtl": _vm.direction,
      "search-options": {
        enabled: false,
        externalQuery: _vm.searchTerm
      },
      "select-options": {
        enabled: true,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true
        // perPage: row_option.size
      },
      "group-options": {
        enabled: true
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered",
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-header-row",
      fn: function fn(props) {
        return [_c('BBadge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "BookIcon"
          }
        }), _c('strong', [_vm._v(_vm._s(_vm.labelForm(props.row.label)))])], 1), _c('BBadge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" Jumlah Data : " + _vm._s(props.row.children.length) + " ")])];
      }
    }, {
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'form_status_main' ? _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "variant": _vm.statusVariant(props.row.form_status)
          }
        }, [_vm._v(" " + _vm._s(props.row.form_status) + " ")]), _c('strong', [_c('small', {
          staticClass: "text-secondary"
        }, [_c('strong', [_vm._v(" " + _vm._s(_vm.customValueDate(props.row)) + " ")]), _c('feather-icon', {
          staticClass: "cursor-pointer ml-1",
          attrs: {
            "icon": _vm.customValueDate(props.rows).includes('AM') ? 'SunIcon' : 'MoonIcon'
          }
        })], 1)])], 1) : props.column.field === 'no_pkk' ? _c('span', [props.row.detail_type !== 'form_tug_boat' ? _c('span', [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_pkk);
            }
          }
        }, [_c('strong', [_vm._v("Main Boat :")]), _vm._v(" " + _vm._s(props.row.no_pkk) + " ")]), props.row.no_pkk_tug_boat ? _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_pkk_tug_boat);
            }
          }
        }, [_c('br'), _c('strong', [_vm._v("Tug Boat :")]), _vm._v(" " + _vm._s(props.row.no_pkk_tug_boat) + " ")]) : _vm._e()]) : _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_pkk_tug_boat);
            }
          }
        }, [_c('strong', [_vm._v("Tug Boat :")]), _vm._v(" " + _vm._s(props.row.no_pkk_tug_boat) + " ")])]) : props.column.field === 'boat_name' ? _c('span', [props.row.detail_type !== 'form_tug_boat' ? _c('span', [_c('strong', [_vm._v("Main Boat :")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.boat_name);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.boat_name) + " ")]), props.row.name_tug_boat ? _c('span', [_c('br'), _c('strong', [_vm._v("Tug Boat :")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.name_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.name_tug_boat) + " ")])]) : _vm._e()]) : _c('span', [_vm._v(" " + _vm._s(props.row.name_tug_boat) + " ")])]) : props.column.field === 'kegiatan' ? _c('span', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.kegiatanUI(props.row.kegiatan))
          }
        })]) : props.column.field === 'no_doc' ? _c('span', [props.row.jenis_kapal === 'LCT / KM / MT / SPOB / Sea Truck' || props.row.jenis_kapal === 'Barge' ? _c('span', [_c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('strong', [_vm._v("Main Boat")])])]), _c('span', [_c('strong', [_vm._v(" RKBM: ")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_rkbm);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_rkbm || '-') + " ")])]), _c('br'), _c('span', [_c('b-badge', {
          attrs: {
            "variant": "".concat(props.row.no_rpkro ? 'light-success' : 'light-danger')
          }
        }, [_vm._v("RPKRO")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_rpkro);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_rpkro || '-') + " ")])], 1), _c('br'), _c('span', [_c('strong', [_vm._v(" SPOG: ")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_spog);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_spog || '-') + " ")])])]) : _vm._e(), props.row.jenis_kapal === 'Barge' || props.row.jenis_kapal === 'TUG BOAT' ? _c('span', [_c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('strong', [_vm._v("Tug Boat")])])]), _c('span', [_c('b-badge', {
          attrs: {
            "variant": "".concat(props.row.no_rpkro_tb ? 'light-success' : 'light-danger')
          }
        }, [_vm._v("RPKRO")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_rpkro_tb);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_rpkro_tb || '-') + " ")])], 1), _c('br'), _c('span', [_c('strong', [_vm._v(" SPOG: ")]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "cursor-pointer",
          attrs: {
            "title": "Click to copy"
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_spog_tb);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.no_spog_tb || '-') + " ")])])]) : _vm._e()]) : props.column.field === 'posisi_jetty' ? _c('span', [_c('span', [_vm._v(" " + _vm._s(props.row.rencana_posisi || '-') + " ")]), _c('br'), _c('span', [_vm._v(_vm._s(props.row.rencana_posisi_baris || '-') + " ")])]) : props.column.field === 'summary' ? _c('span', [props.row.detail_type === 'form_chain_tbbm' ? _c('b-button', {
          attrs: {
            "variant": props.row.form_status !== 'KAPAL BERANGKAT' ? props.row.form_status === 'KEGIATAN DIBATALKAN' ? 'outline-danger' : 'outline-warning' : 'outline-success',
            "to": {
              name: 'dashboard-monitoring-rrv-tbbm-view',
              params: {
                id: props.row.detail_id,
                api: 'form_chain_tbbm',
                previous_link: {
                  name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                }
              }
            },
            "size": "sm",
            "block": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": props.row.form_status === 'NO PERIJINAN BELUM LENGKAP' ? 'PenToolIcon' : 'PenToolIcon',
            "size": "25"
          }
        })], 1) : _vm._e(), props.row.detail_type === 'form_chain_tbbm' ? _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "sm",
            "to": {
              name: 'dashboard-monitoring-rrv-tbbm-summary',
              params: {
                id: props.row.detail_id,
                api: 'form_chain_tbbm',
                previous_link: {
                  name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        })], 1) : _vm._e(), props.row.detail_type === 'form_chain_hm' ? _c('b-button', {
          attrs: {
            "variant": props.row.form_status !== 'KAPAL BERANGKAT' ? 'outline-warning' : 'outline-success',
            "to": {
              name: 'dashboard-monitoring-rrv-hulu-migas-view',
              params: {
                id: props.row.detail_id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                }
              }
            },
            "size": "sm",
            "block": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": props.row.form_status === 'NO PERIJINAN BELUM LENGKAP' ? 'PenToolIcon' : 'PenToolIcon',
            "size": "25"
          }
        })], 1) : _vm._e(), props.row.detail_type === 'form_chain_hm' ? _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "sm",
            "to": {
              name: 'dashboard-monitoring-rrv-hulu-migas-summary',
              params: {
                id: props.row.detail_id,
                api: 'form_chain_hm',
                previous_link: {
                  name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        })], 1) : _vm._e(), props.row.detail_type === 'form_tug_boat' ? _c('b-button', {
          attrs: {
            "variant": props.row.kegiatan !== 'KAPAL BERANGKAT' ? props.row.kegiatan === 'KEGIATAN DIBATALKAN' ? 'outline-danger' : 'outline-warning' : 'outline-success',
            "block": "",
            "to": {
              name: 'dashboard-monitoring-rrv-tug-boat-view',
              params: {
                id: props.row.detail_id,
                api: 'form_tug_boat',
                previous_link: {
                  name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                }
              }
            },
            "size": "sm"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": 'PenToolIcon',
            "size": "25"
          }
        })], 1) : _vm._e(), props.row.detail_type === 'form_tug_boat' ? _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "sm",
            "to": {
              name: 'dashboard-monitoring-rrv-tug-boat-summary',
              params: {
                id: props.row.detail_id,
                api: '',
                previous_link: {
                  name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        })], 1) : _vm._e()], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', [_vm._v("Detail Data")])], 1)], 1)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }