<template>
  <div class="monitoring">
    <BCardActions
      :title="`Realtime Monitoring - Permohonan Kapal (Hulu Migas)`"
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >
      <h4>
        <b-alert variant="warning" show class="mb-0">
          <div class="alert-body">
            <feather-icon icon="AlertTriangleIcon" class="mr-25" />
            <span>
              Document Permohonan Kapal yang belum realisasi Nomor
              <strong>
                <b-badge variant="light-danger">RPKRO</b-badge>
              </strong>
            </span>
          </div>
        </b-alert>
      </h4>
      <!-- <b-row class="yo-row mb-1" align-h="between" align-v="center">
        <b-col cols="4">
          <b-form-group label="Kondisi" label-for="kondisi">
            <v-select
              id="kondisi"
              v-model="stateFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="stateFilterOptions"
              :reduce="(option) => option.value"
              :selectable="(option) => !option.value.includes('null')"
              label="text"
              placeholder="Pilih Kondisi"
              item-value="value"
              item-text="text"
            >
              <template #option="{ text, value }">
                <feather-icon
                  v-if="value != 'null'"
                  icon="AnchorIcon"
                  size="16"
                  class="align-middle mr-25"
                />
                <strong
                  ><span> {{ text }}</span></strong
                >
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <transition
            name="slide-fade"
            mode="slide-fade"
            v-if="stateFilter === 'RENCANA SANDAR' ? false : true"
          >
            <b-row>
              <b-col cols="6">
                <b-form-group label="From Date" label-for="from-date-lbl">
                  <flat-pickr
                    id="from-date-lbl"
                    v-model="lte"
                    class="form-control"
                    static="true"
                    :config="dpconfig"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="To Date" label-for="to-date-lbl">
                  <flat-pickr
                    id="to-date-lbl"
                    v-model="gte"
                    class="form-control"
                    static="true"
                    :config="dpconfig"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </transition>
        </b-col>
      </b-row> -->
      <div v-if="yo_isLoading" class="progress-wrapper">
        <b-card-text class="mb-0">
          Memuat data… {{ yo_isLoadingValue + '%' }}
        </b-card-text>
        <b-progress
          :key="yo_isLoadingValue"
          animated
          :value="yo_isLoadingValue"
          variant="info"
          max="100"
          :class="'progress-bar-info'"
        />
      </div>
      <!-- table -->
      <vue-good-table
        :key="yoRender"
        class="yo-good-table"
        mode="remote"
        :total-rows="total_records"
        :columns="columns"
        :rows="rows"
        :is-loading="yo_isLoading"
        :rtl="direction"
        :search-options="{
          enabled: false,
          externalQuery: searchTerm
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-search="onSearchTable"
        :pagination-options="{
          enabled: true
          // perPage: row_option.size
        }"
        :group-options="{
          enabled: true
        }"
        compact-mode
        theme="polar-bear"
        style-class="vgt-table bordered"
        :line-numbers="true"
        :fixed-header="false"
        max-height="700px"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <!--
         -->
        <template slot="table-header-row" slot-scope="props">
          <BBadge variant="primary" class="mr-1">
            <feather-icon icon="BookIcon" class="mr-25" />
            <strong>{{ labelForm(props.row.label) }}</strong>
          </BBadge>
          <BBadge variant="secondary">
            Jumlah Data : {{ props.row.children.length }}
          </BBadge>
        </template>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Status -->

          <span v-if="props.column.field === 'form_status_main'">
            <b-badge
              :variant="statusVariant(props.row.form_status)"
              class="d-block"
            >
              {{ props.row.form_status }}
            </b-badge>
            <strong>
              <small class="text-secondary">
                <strong>
                  {{ customValueDate(props.row) }}
                </strong>
                <feather-icon
                  class="cursor-pointer ml-1"
                  :icon="
                    customValueDate(props.rows).includes('AM')
                      ? 'SunIcon'
                      : 'MoonIcon'
                  "
                />
              </small>
            </strong>
          </span>
          <span v-else-if="props.column.field === 'no_pkk'">
            <span v-if="props.row.detail_type !== 'form_tug_boat'">
              <span
                class="cursor-pointer"
                v-b-tooltip.hover
                title="Click to copy"
                @click="copyText(props.row.no_pkk)"
              >
                <strong>Main Boat :</strong> {{ props.row.no_pkk }}
              </span>
              <span
                v-if="props.row.no_pkk_tug_boat"
                class="cursor-pointer"
                v-b-tooltip.hover
                title="Click to copy"
                @click="copyText(props.row.no_pkk_tug_boat)"
              >
                <br />
                <strong>Tug Boat :</strong> {{ props.row.no_pkk_tug_boat }}
              </span>
            </span>
            <span
              v-else
              class="cursor-pointer"
              v-b-tooltip.hover
              title="Click to copy"
              @click="copyText(props.row.no_pkk_tug_boat)"
            >
              <strong>Tug Boat :</strong> {{ props.row.no_pkk_tug_boat }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'boat_name'">
            <span v-if="props.row.detail_type !== 'form_tug_boat'">
              <strong>Main Boat :</strong>
              <span
                class="cursor-pointer"
                v-b-tooltip.hover
                title="Click to copy"
                @click="copyText(props.row.boat_name)"
              >
                {{ props.row.boat_name }}
              </span>
              <span v-if="props.row.name_tug_boat">
                <br />
                <strong>Tug Boat :</strong>
                <span
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Click to copy"
                  @click="copyText(props.row.name_tug_boat)"
                >
                  {{ props.row.name_tug_boat }}
                </span>
              </span>
            </span>
            <span v-else>
              {{ props.row.name_tug_boat }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'kegiatan'">
            <span v-html="kegiatanUI(props.row.kegiatan)"></span>
          </span>
          <span v-else-if="props.column.field === 'no_doc'">
            <span
              v-if="
                props.row.jenis_kapal === 'LCT / KM / MT / SPOB / Sea Truck' ||
                props.row.jenis_kapal === 'Barge'
              "
            >
              <div class="divider">
                <div class="divider-text"><strong>Main Boat</strong></div>
              </div>
              <span>
                <strong> RKBM: </strong>
                <!-- <br /> -->
                <span
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Click to copy"
                  @click="copyText(props.row.no_rkbm)"
                >
                  {{ props.row.no_rkbm || '-' }}
                </span>
                <!-- SL004.IDSRI.0123.000416 -->
              </span>
              <br />
              <span>
                <b-badge
                  :variant="`${
                    props.row.no_rpkro ? 'light-success' : 'light-danger'
                  }`"
                  >RPKRO</b-badge
                >
                <!-- <br /> -->
                <!-- IDSRI-SALPAL-01153 -->
                <span
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Click to copy"
                  @click="copyText(props.row.no_rpkro)"
                >
                  {{ props.row.no_rpkro || '-' }}
                </span>
              </span>
              <br />
              <span>
                <strong> SPOG: </strong>
                <!-- <br /> -->
                <!-- SPOG.IDSRI.1222.0013840 -->
                <span
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Click to copy"
                  @click="copyText(props.row.no_spog)"
                >
                  {{ props.row.no_spog || '-' }}
                </span>
              </span>
            </span>
            <span
              v-if="
                props.row.jenis_kapal === 'Barge' ||
                props.row.jenis_kapal === 'TUG BOAT'
              "
            >
              <div class="divider">
                <div class="divider-text"><strong>Tug Boat</strong></div>
              </div>
              <span>
                <b-badge
                  :variant="`${
                    props.row.no_rpkro_tb ? 'light-success' : 'light-danger'
                  }`"
                  >RPKRO</b-badge
                >
                <!-- <br /> -->
                <!-- IDSRI-SALPAL-01153 -->
                <span
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Click to copy"
                  @click="copyText(props.row.no_rpkro_tb)"
                >
                  {{ props.row.no_rpkro_tb || '-' }}
                </span>
              </span>
              <br />
              <span>
                <strong> SPOG: </strong>
                <!-- <br /> -->
                <span
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Click to copy"
                  @click="copyText(props.row.no_spog_tb)"
                >
                  {{ props.row.no_spog_tb || '-' }}
                </span>
              </span>
            </span>
          </span>

          <span v-else-if="props.column.field === 'posisi_jetty'">
            <span>
              {{ props.row.rencana_posisi || '-' }}
            </span>
            <br />
            <span>{{ props.row.rencana_posisi_baris || '-' }} </span>
            <!-- <div v-if="props.row.no_pindah === 'true'">
              <hr />
              <h4>
                <b-badge style="cursor: not-allowed" variant="danger d-block">
                  <span>Pernah Pindah</span>
                </b-badge>
              </h4>
            </div>
            <div
              v-else-if="
                props.row.no_pindah !== 'false' && props.row.no_pindah !== null
              "
            >
              <h4>
                <b-badge style="cursor: not-allowed" variant="danger d-block">
                  <span>Pernah Pindah</span>
                </b-badge>
              </h4>
            </div> -->
          </span>
          <span v-else-if="props.column.field === 'summary'">
            <b-button
              v-if="props.row.detail_type === 'form_chain_tbbm'"
              :variant="
                props.row.form_status !== 'KAPAL BERANGKAT'
                  ? props.row.form_status === 'KEGIATAN DIBATALKAN'
                    ? 'outline-danger'
                    : 'outline-warning'
                  : 'outline-success'
              "
              :to="{
                name: 'dashboard-monitoring-rrv-tbbm-view',
                params: {
                  id: props.row.detail_id,
                  api: 'form_chain_tbbm',
                  previous_link: {
                    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                  }
                }
              }"
              size="sm"
              block
            >
              <feather-icon
                class="cursor-pointer"
                :icon="
                  props.row.form_status === 'NO PERIJINAN BELUM LENGKAP'
                    ? 'PenToolIcon'
                    : 'PenToolIcon'
                "
                size="25"
              />
              <!-- Realisasikan -->
            </b-button>
            <b-button
              v-if="props.row.detail_type === 'form_chain_tbbm'"
              class="mt-1"
              variant="outline-primary"
              block
              size="sm"
              :to="{
                name: 'dashboard-monitoring-rrv-tbbm-summary',
                params: {
                  id: props.row.detail_id,
                  api: 'form_chain_tbbm',
                  previous_link: {
                    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                  }
                }
              }"
            >
              <feather-icon
                class="cursor-pointer"
                icon="ActivityIcon"
                size="25"
              />
              <!-- Summary -->
            </b-button>
            <b-button
              v-if="props.row.detail_type === 'form_chain_hm'"
              :variant="
                props.row.form_status !== 'KAPAL BERANGKAT'
                  ? 'outline-warning'
                  : 'outline-success'
              "
              :to="{
                name: 'dashboard-monitoring-rrv-hulu-migas-view',
                params: {
                  id: props.row.detail_id,
                  api: 'form_chain_hm',
                  previous_link: {
                    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                  }
                }
              }"
              size="sm"
              block
            >
              <feather-icon
                class="cursor-pointer"
                :icon="
                  props.row.form_status === 'NO PERIJINAN BELUM LENGKAP'
                    ? 'PenToolIcon'
                    : 'PenToolIcon'
                "
                size="25"
              />
              <!-- Realisasikan -->
            </b-button>
            <b-button
              v-if="props.row.detail_type === 'form_chain_hm'"
              class="mt-1"
              variant="outline-primary"
              block
              size="sm"
              :to="{
                name: 'dashboard-monitoring-rrv-hulu-migas-summary',
                params: {
                  id: props.row.detail_id,
                  api: 'form_chain_hm',
                  previous_link: {
                    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                  }
                }
              }"
            >
              <feather-icon
                class="cursor-pointer"
                icon="ActivityIcon"
                size="25"
              />
              <!-- Summary -->
            </b-button>
            <b-button
              v-if="props.row.detail_type === 'form_tug_boat'"
              :variant="
                props.row.kegiatan !== 'KAPAL BERANGKAT'
                  ? props.row.kegiatan === 'KEGIATAN DIBATALKAN'
                    ? 'outline-danger'
                    : 'outline-warning'
                  : 'outline-success'
              "
              block
              :to="{
                name: 'dashboard-monitoring-rrv-tug-boat-view',
                params: {
                  id: props.row.detail_id,
                  api: 'form_tug_boat',
                  previous_link: {
                    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                  }
                }
              }"
              size="sm"
            >
              <feather-icon
                class="cursor-pointer"
                :icon="'PenToolIcon'"
                size="25"
              />
              <!-- Realisasikan -->
            </b-button>
            <b-button
              v-if="props.row.detail_type === 'form_tug_boat'"
              class="mt-1"
              variant="outline-primary"
              block
              size="sm"
              :to="{
                name: 'dashboard-monitoring-rrv-tug-boat-summary',
                params: {
                  id: props.row.detail_id,
                  api: '',
                  previous_link: {
                    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal'
                  }
                }
              }"
            >
              <feather-icon
                class="cursor-pointer"
                icon="ActivityIcon"
                size="25"
              />
              <!-- Summary -->
            </b-button>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="AnchorIcon" class="mr-50" />
                  <span>Detail Data</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else> {{ props.formattedRow[props.column.field] }} </span>
        </template>
      </vue-good-table>
    </BCardActions>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotificationRiver } =
      useAppConfig()

    // const isCollapse = computed(() => isCollapse.value === true)
    // const triggerFCM = computed(() => triggerFCM.value >= 1)

    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      // isCollapse,
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  data() {
    return {
      yoRender: 1,
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      // 2022-10-14T05:50:23.000Z
      lte: moment().format('yyyy-MM-DDT01:00'),
      gte: moment().format('yyyy-MM-DDT23:59'),
      stateFilter: 'RENCANA SANDAR HM',
      stateFilterOptions: [
        {
          text: 'Pilih Kondisi Kapal',
          value: 'null'
        },
        {
          text: 'RENCANA SANDAR HM',
          value: 'RENCANA SANDAR HM'
        },
        {
          text: 'PERNAH RENCANA SANDAR HM',
          value: 'PERNAH RENCANA SANDAR HM'
        }
      ],
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      onFiltering: false,
      total_records: 0,
      columns: [
        {
          label: 'Status',
          field: 'form_status_main',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Status'
          },
          width: '250px'
        },
        {
          label: 'No Dokumen',
          field: 'no_doc',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari No Dokumen'
          },
          width: '300px'
        },
        {
          label: 'Nomor PKK',
          field: 'no_pkk',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari No PKK'
          },
          width: '200px'
        },
        {
          label: 'Nama Kapal',
          field: 'boat_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Kapal'
          },
          width: '200px'
        },
        {
          label: 'Kegiatan',
          field: 'kegiatan',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Kegiatan'
          }
        },
        {
          label: 'Posisi Jetty',
          field: 'posisi_jetty',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari No Jetty'
          }
        },
        {
          label: 'Pemilik',
          field: 'pemilik',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Pemilik'
          }
        },
        // {
        //   label: 'Rencana Sandar',
        //   field: 'rencana_sandar',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Cari Rencana Sandar'
        //   },
        //   formatFn: this.dateHumanReadAble
        // },
        {
          label: 'Summary',
          field: 'summary'
        }
      ],
      rows: [],
      b_dropdown: null,
      serverParams: {
        columnFilters: {},
        searchTerm: '',
        sort: {
          field: 'rencana_sandar',
          type: 'DESC'
        },
        page: 1,
        perPage: 10
      },
      // row_option: {
      //   form_status_main: 'KAPAL SANDAR',
      //   size: 15, // page length
      //   size_option: ['5', '10', '15', '20', '100'], // page option
      //   current_page: 1, // as page
      //   total_items: 20,
      //   total_pages: 3,
      // },
      searchTerm: ''
    }
  },
  computed: {
    dropdownStatusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'TAMPILKAN SEMUA DATA': 'primary',
        'KAPAL BERANGKAT': 'success',
        'KAPAL (CANCEL/KEGIATAN DIBATALKAN)': 'danger',
        'KEGIATAN DIBATALKAN': 'danger',
        'KAPAL SANDAR': 'warning',
        'KAPAL RENCANA SANDAR': 'info'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        'KAPAL BERANGKAT': 'light-success',
        CANCEL: 'light-danger',
        'KEGIATAN DIBATALKAN': 'light-danger',
        'KAPAL SANDAR': 'light-warning',
        'KAPAL RENCANA SANDAR': 'light-info'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  watch: {
    lte(params) {
      console.log('new ', params)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.fetchData(true, 'KAPAL SANDAR')
      }, 300)
    },
    gte(params) {
      console.log('new ', params)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.fetchData(true, 'KAPAL SANDAR')
      }, 300)
    },
    stateFilter(params) {
      console.log('new ', params)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.fetchData(true, 'KAPAL SANDAR')
      }, 300)
    },
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotificationRiver() {
      console.log(
        'RMSungai.vue : an incoming mesage key : ',
        this.anIncomingNotificationRiver
      )

      let high_light_data = this.anIncomingNotificationRiver.data[`action`]
      let will_be_refresh = [
        'Berencana Sandar',
        'Document RPKRO Sudah diterbitkan',
        'Document lengkap, Kapal Boleh Sandar',
        'Telah Sandar',
        'Kapal Sandar',
        'Membatalkan Kegiatan'
      ]
      let go_refresh = will_be_refresh.some((data) =>
        high_light_data.includes(data)
      )
      console.log('vgo_refresh::', go_refresh)
      if (go_refresh) {
        this.fetchData(true, 'KAPAL SANDAR')
      }
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
      // this.yoRender += 1
    }
  },
  mounted() {
    document.body.style.zoom = '70%'
  },
  destroyed() {
    document.body.style.zoom = '70%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.fetchData(true, 'KAPAL SANDAR')
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    moment,
    kegiatanUI(value) {
      return value.replace(',', '<br/>')
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    labelForm(row) {
      if (row === 'form_chain_hm') {
        return 'Form Hulu Migas'
      } else if (row === 'form_chain_tbbm') {
        return 'Form TBBM'
      } else if (row === 'form_tug_boat') {
        return 'Form Tug Boat'
      }
    },
    variantLabelForm(row) {
      if (row === 'form_chain_hm') {
        return 'Form Hulu Migas'
      } else if (row === 'form_chain_tbbm') {
        return 'Form TBBM'
      } else if (row === 'form_tug_boat') {
        return 'Form Tug Boat'
      }
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        this.updateParams({ page: 1 })
        console.log('search: ', params.searchTerm)
        this.fetchData(true, 'KAPAL SANDAR')
      }, 300)
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    // actionDisable(userID, isTBBM, pemilik) {
    //   if (this.$can('manage', 'all')) {
    //     return false
    //   }
    //   if (this.$can('SAL', '') || this.$can('manage', 'special')) {
    //     if (isTBBM === 'form_tbbm') {
    //       if (this.yoUserData.group.name === pemilik) {
    //         return false
    //       }
    //       if (this.$can('SAL', '')) {
    //         return false
    //       }
    //       return true
    //     }
    //     if (isTBBM === 'form_hulu_migas') {
    //       if (['TEM', 'PATRA'].includes(this.yoUserData.group.name)) {
    //         return true
    //       }
    //       if (this.$can('SAL', '')) {
    //         return false
    //       }
    //       return true
    //     }
    //     return false
    //   }
    //   if (this.$can('TBBM', '') || this.$can('Hulu Migas', '')) {
    //     if (this.yoUserData.id === userID) {
    //       // console.log('by user:', this.yoUserData.id)
    //       return false
    //     }
    //     // console.log('by user to disabled: ', this.yoUserData.id)
    //     return true
    //   }
    //   // console.log('nothing: ', this.yoUserData.id)
    //   return true
    // },
    // router(value) {
    //   let string
    //   if (value === 'form_tbbm') {
    //     string = 'dashboard-monitoring-rrv-tbbm-view'
    //   } else {
    //     string = 'dashboard-monitoring-rrv-hulu-migas-view'
    //   }
    //   return string
    // },
    // api(value) {
    //   let string
    //   if (value === 'form_tbbm') {
    //     string = 'form_tbbm'
    //   } else {
    //     string = 'form_hulu_migas'
    //   }
    //   return string
    // },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    customValueDate(value) {
      if (value) {
        let string = value.rencana_sandar
        if (value.sandar_start_time) {
          string = value.sandar_start_time
          // console.log('1')
        }
        if (value.kegiatan_start_time) {
          string = value.kegiatan_start_time
          // console.log('2')
        }
        if (value.kegiatan_end_time) {
          string = value.kegiatan_end_time
          // console.log('3')
        }
        if (value.sandar_end_time) {
          string = value.sandar_end_time
          // console.log('4', value.sandar_end_time)
        }
        if (value.kegiatan_batal_time) {
          string = value.kegiatan_batal_time
          // console.log('5')
        }
        return moment(string).format('D MMMM YYYY- HH:mm')
      }

      return 'am'
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      console.log('onPageChange: ', params)
      // this.updateParams({ page: params })
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, 'KAPAL SANDAR')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      // this.updateParams({ perPage: params })
      this.updateParams({ perPage: params.currentPerPage })
      this.fetchData(true, 'KAPAL SANDAR')
    },

    onSortChange(params) {
      console.log(params)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, 'KAPAL SANDAR')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, 'KAPAL SANDAR')
    },
    fetchData(state, keyword) {
      this.isTimerStart()
      const { page } = this.serverParams
      this.serverParams = {
        ...this.serverParams,
        page,
        stateFilter: this.stateFilter, // kapal pernah|RENCANA sandar
        timeGT: this.gte,
        timeLTE: this.lte,
        keyword
      }
      useJwt.http
        .get(`vw_monitor_jetty/RENCANA SANDAR HM`, {
          params: { ...this.serverParams }
        })
        .then((res) => {
          const { vw_monitor_jettys, total_records } = res.data
          this.total_records = total_records
          this.rows = vw_monitor_jettys
          this.isTimerDone()
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
                .divider {
                  margin: 0.1rem 0 !important;
                }
                hr {
                  margin-top: 0.2rem;
                  margin-bottom: 0.2rem;
                }
                span.warning-text {
                  position: relative;
                  text-transform: uppercase;
                  letter-spacing: 2px;
                  font-size: 0.7vw;
                  font-weight: 900;
                  text-decoration: none;
                  color: white;
                  display: inline-block;
                  background-size: 120% 100%;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  -moz-background-clip: text;
                  -moz-text-fill-color: transparent;
                  -ms-background-clip: text;
                  -ms-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                  background-image: linear-gradient(
                    45deg,
                    #7794ff,
                    #44107a,
                    #ff1361,
                    #fff800
                  );
                  animation: 0.8s tiltshaking infinite alternate;

                  @keyframes tiltshaking {
                    0% {
                      transform: rotate(0deg);
                    }
                    25% {
                      transform: rotate(5deg);
                    }
                    50% {
                      transform: rotate(0eg);
                    }
                    75% {
                      transform: rotate(-5deg);
                    }
                    100% {
                      transform: rotate(0deg);
                    }
                    0% {
                      transform: skewX(-30deg);
                      background-color: red;
                    }
                    5% {
                      transform: skewX(30deg);
                    }
                    10% {
                      transform: skewX(-30deg);
                    }
                    15% {
                      transform: skewX(30deg);
                      background-color: blue;
                    }
                    20% {
                      transform: skewX(0deg);
                    }
                    100% {
                      transform: skewX(0deg);
                    }
                  }
                }
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
